import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './assets/main.css'
import App from './App';
import reportWebVitals from './reportWebVitals';
import Main from './components/main';
import Header from './components/header';
import Footer from './components/footer';
import Login from './components/login';
import Register from './components/register';
import BusinessLogin from './components/buser-login';
import BusinesssAdmin from './components/buadmin';
import Owner from './components/owner-form';
import TenantForm from './components/tenant-form';
import PropertyAgent from './components/property-form';
import Userform from './components/user-forms';
import {BrowserRouter as Router,Route,Switch} from 'react-router-dom';
import {ApolloClient,InMemoryCache,HttpLink,from,ApolloProvider} from '@apollo/client';
import {createUploadLink} from 'apollo-upload-client'
import { setContext } from '@apollo/client/link/context';

const link = from([
  createUploadLink(
     {
       uri:'http://182.160.118.196:82/graphql',
      // uri:'http://182.160.118.196:93/graphql',
       headers:{ 'Content-Type': 'application/json'}
     }
     )
 ])
 
 const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
});

const client = new ApolloClient({
  link: authLink.concat(link),
  cache: new InMemoryCache()
});

ReactDOM.render(
  <React.StrictMode>
     <ApolloProvider client={client}>
    <Router>
    {/* <Header></Header> */}
      <Switch>
        <Route exact path="/" component={App}></Route>
        <Route exact path="/main" component={Main}></Route>
        <Route exact path="/login" component={Login}></Route>
        <Route exact path="/register" component={Register}></Route>
        <Route exact path="/buadmin-login" component={BusinessLogin}></Route>
        <Route exact path="/businessadmin" component={BusinesssAdmin}></Route>
        <Route exact path="/owner-form" component={Owner}></Route>
        <Route exact path="/tenant-form" component={TenantForm}></Route>
        <Route exact path="/property-form" component={PropertyAgent}></Route>
        <Route exact path="/user-form" component={Userform}></Route>
      </Switch>
    </Router>
    </ApolloProvider>

  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
