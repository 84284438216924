import React from 'react'

export default function Pricing() {
    return (
        <div>
            <div className="px-2 space-y-5 py-5">
                <h1 className="text-center text-4xl  font-bold">PRICING</h1>
                <div className="bg-green-600 h-1 text-center w-52 mx-auto"></div>
                <div className="tracking-tighter flex md:flex-row xs:flex-col md:space-x-2 xs:space-y-5 text-blue-900">
                    <div className="rounded-md px-2 space-y-5  bg-opacity-50 md:w-1/4 xs:w-full xs:w-full wow animate__lightSpeedInLeft" data-wow-duration="2s" data-wow-delay=".10s">
                        <h1 className=" text-xl font-bold">MyKeypee Service</h1>
                        <p className="text-justify space-y-5">
                           <b>10%</b>  of the first month rental fees, or <b>RM 50</b> for annual services fee if the keys are kept or return to owner for any reason
                        </p>
                    </div>

                    <div className="rounded-md px-2 space-y-5  bg-opacity-50 md:w-1/4 xs:w-full animate__lightSpeedInLeft" data-wow-duration="2s" data-wow-delay=".20s">
                        <h1 className=" text-xl font-bold">Keysafer Services</h1>
                        <p className="text-justify space-y-5">
                            <b>RM 50</b> (upfront payment) annually for up to 5 keys. Same fees apply for keys return to owner less than one year.
                        </p>
                    </div>

                    <div className="rounded-md px-2 space-y-5  bg-opacity-50 md:w-1/4 xs:w-full wow animate__lightSpeedInLeft" data-wow-duration="2s" data-wow-delay=".30s">
                        <h1 className=" text-xl font-bold">Keysitter Service</h1>
                        <p className="text-justify space-y-5">
                            To be quote based on the type of service request.
                        </p>
                    </div>

                    <div className="rounded-md px-2 space-y-5  bg-opacity-50 md:w-1/4 xs:w-full wow animate__lightSpeedInLeft" data-wow-duration="2s" data-wow-delay=".50s">
                        <h1 className=" text-xl font-bold">MyKeypee Extra Service</h1>
                        <p className="text-justify space-y-5">
                            To be quote based on the type of service request
                        </p>
                    </div>

                </div>
            </div>
        </div>
    )
}
