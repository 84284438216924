import React,{useState} from 'react';
import {useMutation} from '@apollo/client'
import {SIGNUP} from '../GraphQL/Mutation'
import { useHistory } from 'react-router';
import {Multiselect} from 'multiselect-react-dropdown';

const Users = [
    { value: 'OWNER', label: 'OWNER' },
    { value: 'TENANT', label: 'TENANT' },
    { value: 'PROPERTYAGENT', label: 'PROPERTYAGENT' }, 
  ];

export default function Register() {

    const [name,setName]=useState("")
    const [email,setEmail]=useState("")
    const [password,setPassword] = useState("")
    const [selectuser, setSelectuser] = useState("")

    const submitHandler=(e)=>{
        e.preventDefault()
    }

    const onChangeUser=async(e)=>{
        await setSelectuser(Array.isArray(e)?e.map(x=>{
          return {selectuser:x.label}
        }):[])
        console.log(selectuser)
      }

    const history = useHistory();

    const [register] = useMutation(SIGNUP)

    const onSubmitForm = (e) =>{
        e.preventDefault();

        register({
            variables:{
                name: name,
                email:email,
                password:password,
                type: selectuser
            }
        }).then(res=>{
            console.log(res.data)
            localStorage.setItem('token',res.data.register)
            switch (selectuser) {
                case "OWNER":
                  history.push('/owner-form')
                  break;
                case "TENANT":
                    history.push('/tenant-form')
                  break;
                case "PROPERTYAGENT":
                  history.push('/property-form')
                  break;
              }
        })
    }

    return (
        <div className="container p-10 bg-green-600 h-screen">
            <div className='w-full max-w-md m-auto bg-yellow-50 rounded-lg border shadow-default py-10 px-16'>
                <h1 className='text-2xl font-medium text-primary mt-4 mb-12 text-center'>
                    Register Your Account 🔐
                </h1>

                <form>

                <div className="flex flex-col space-y-2">
                        <label htmlFor='name'>Name</label>
                        <input
                            className={`w-full p-2 text-primary border rounded-md outline-none text-sm transition duration-150 ease-in-out mb-4 
                            focus:ring-2 focus:ring-blue-600 `}
                            type="text"
                            name="name"
                            value={name}
                            onChange={e=>setName(e.target.value)}

                        />
                    </div>

                    <div className="flex flex-col space-y-2">
                        <label htmlFor='email'>Email</label>
                        <input
                            className={`w-full p-2 text-primary border rounded-md outline-none text-sm transition duration-150 ease-in-out mb-4 
                            focus:ring-2 focus:ring-blue-600 `}
                            type="email"
                            name="email"
                            value={email}
                            onChange={e=>setEmail(e.target.value)}

                        />
                    </div>

                    <div className="flex flex-col space-y-2">
                        <label htmlFor='password'>Password</label>
                        <input
                            className={`w-full p-2 text-primary border rounded-md outline-none text-sm transition duration-150 ease-in-out mb-4
                            focus:ring-2 focus:ring-blue-600 `}
                            type="password"
                            name="password"
                            value={password}
                            onChange={e=>setPassword(e.target.value)}
                        />
                    </div>


                    {/* <div className="flex flex-col">
                        <label htmlFor='password'>Select User</label>
                        <select className="" value={selectuser} onChange={e=>setSelectuser(e.target.value)}>
                            <option>Select</option>
                            <option>OWNER</option>
                            <option>TENANT</option>
                            <option>PROPERTYAGENT</option>
                        </select>
                    </div> */}

                     <div className="grid grid-rows-2">
                       <label>Select User</label>
                       <Multiselect options={Users} displayValue='label' onSelect={onChangeUser}/>
                     </div>

                    <div className='flex justify-center items-center mt-6'>
                        <button onClick={onSubmitForm} type="submit" className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-6 border border-blue-500 hover:border-transparent rounded shadow">
                            Register
                        </button>
                    </div>
                </form>


            </div>
        </div>
    )
}
