import React from 'react'
import {AiFillFacebook,AiFillInstagram,AiFillTwitterSquare,AiOutlineWhatsApp} from 'react-icons/ai'
import ReactWhatsapp from 'react-whatsapp';

export default function Footer() {
    return (
        <div className="flex md:flex-row xs:flex-col bg-gray-800 text-white md:space-x-10 xs:space-y-5 p-5 justify-between">
            <div className="space-y-2 md:w-1/4 xs:w-full">
                <h2 className="text-2xl">Contact Us</h2>
                <hr></hr>
                <ul className="list-none">
                <li>Email: support@mykeypee.com</li>
                <li>Contact Number: +6013-8055 931</li>
                </ul>
            </div>
            {/* <div className="w-1/4 space-y-5">
                <h1 className="text-2xl">Our Location</h1>
                <hr></hr>
                <iframe width="350" height="300" id="gmap_canvas" src="https://maps.google.com/maps?q=evo%20mall&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
            </div> */}

            <div className="md:w-1/4 xs:w-full space-y-2">
            <h1 className="text-2xl">Operation Hours</h1>
            <hr></hr>
            <span className="font-bold">Open Daily, from 10am to 8pm</span>
            </div>

            <div className="md:w-1/4 xs:w-full space-y-2">
            <h1 className="text-2xl">Follow Us</h1>
            <hr></hr>
            <div className="flex text-blue-500 space-x-2">
                <a href="https://www.facebook.com/MyKeypeeMalaysia" target="_blank"><AiFillFacebook size={50}></AiFillFacebook></a>
            </div>
            </div>

            <div className="bg-green-500 fixed bottom-40 right-10">
             <a href="https://wa.me/message/RXV5QQGTOQZ6H1" target="_blank"><AiOutlineWhatsApp size={50}></AiOutlineWhatsApp></a>  
            </div>
        </div>
    )
}
