import React from 'react'

export default function AboutUs() {
    return (
        <div>
            <div className="px-5 space-y-2 py-5 pb-10">
                <h1 className="wow animate__fadeInUp text-center text-4xl font-bold" data-wow-duration="2s" data-wow-delay=".5s">ABOUT US</h1>
                <div className="flex justify-center">
                    <div className="wow animate__fadeInDown bg-green-600 h-1 w-52 justify-center"></div>
                </div>
                <p className="wow animate__fadeInLeft text-center px-5 md:text-2xl xs:text-xl" data-wow-duration="2s" data-wow-delay=".5s">
                Our story started when the founder, like any white-collar worker, worked hard to brought his dream home. <br/><br/>
                The fairytale continues when he got his second property as an investment, 
                the first rental went well as during the time it was Owner’s driven market. 
                The second rental was not ideal as the market are driven by Tenants. 
                And the third rental took even longer time due to more rental supply during the time.<br/><br/>
                The number of viewings become more on each rental end and founder have no choice but to pass the keys to his most trusted 
                Real Estate Agent to pass along with different Real Estate Agents to avoid multiple fruitless viewing trips.<br/><br/>
                As with any matter in life, his most trusted Real Estate Agent then been assigned to different location, 
                and founder had to look for another trusted partner, which took some time and whole lot of confidence. <br/><br/> 
                Hence, the idea of Keypee Services is born to resolve the gap in between Owners and Real Estate Agencies.

                </p>
            </div>
        </div>
    )
}
