import React from 'react'

export default function Location() {
    return (
        <div className="container mx-auto">
            <div className="flex-col m-auto space-y-5">
               <h1 className="text-center text-4xl font-bold">OUR LOCATION</h1>
                  <div className="flex justify-center">
                  <div className="wow animate__fadeInDown bg-green-600 h-1 w-52 justify-center"></div>
                  </div> 
                <h2 className="text-center text-xl">We are currently serving the following location, *more to come....</h2>

                  <div className="flex md:flex-row xs:flex-col px-5 md:space-x-5 xs:space-y-5 md:space-y-0 ">
                      <div className="relative md:w-1/6 xs:w-full">
                        {/* <div className="rounded-lg shadow-md border-gray-100 border-2 py-2 w-full hover:opacity-75 cursor-pointer space-y-5">
                                <div className="flex-col justify-center">
                                <img src="./images/bangsar-south.png" className="display-block w-full h-72 px-2" />
                                    <h1>Bangsar South</h1>
                                </div>
                            </div> */}
                            <img src="./images/bangsar-south.png" className="display-block w-full h-72 px-2" />
                            <h1 className="absolute bottom-1 text-white px-5 text-xl">Bangsar South, Kuala Lumpur</h1>
                      </div>
                    <div className="relative md:w-1/6 xs:w-full">
                     <img src="./images/bukit-rahman.jpg" className="display-block w-full h-72 px-2" />
                     <h1 className="absolute bottom-1 text-white px-5 text-xl">Bukit Rahman Putra, Selangor</h1>
                      </div>
                     <div className="relative md:w-1/6 xs:w-full">
                     <img src="./images/dpc-hero.jpg" className="display-block w-full h-72 px-2" />
                     <h1 className="absolute bottom-1 text-white px-5 text-xl">Desa Park City, Kuala Lumpur</h1>
                      </div>
                    <div className="relative md:w-1/6 xs:w-full">
                            <img src="./images/sierramas.jpg" className="display-block w-full h-72 px-2" />
                            <h1 className="absolute bottom-1 text-white px-5 text-xl">SierraMas, Selangor</h1>
                      </div>
                    <div className="relative md:w-1/6 xs:w-full">
                    <img src="./images/sri-damansara.jpg" className="display-block w-full h-72 px-2" />
                    <h1 className="absolute bottom-1 text-white px-5 text-xl">Sri Damansara, Kuala Lumpur</h1>
                      </div>
                    <div className="relative md:w-1/6 xs:w-full">
                    <img src="./images/sg.jpg" className="display-block w-full h-72 px-2" />
                    <h1 className="absolute bottom-1 text-white px-5 text-xl">Sungai Buloh, Selangor</h1>
                      </div>
                  </div>
            </div>
        </div>
    )
}
