import React, {useEffect, useState} from 'react'
import {useMutation,useQuery} from '@apollo/client'
import {LOGIN} from '../GraphQL/Mutation'
import {LOAD_ME, LOGIN_ME} from '../GraphQL/Query';
import { useHistory } from 'react-router'

export default function Login() {

    //const [me, setMe] = useState([])

    const [email,setEmail]=useState("")
    const [password,setPassword] = useState("")

    const submitHandler=(e)=>{
        e.preventDefault()
    }

    const history = useHistory();

    const { loading, data } = useQuery(LOAD_ME)

    useEffect(() => {
        if (data) {
            const usertype = data.me ? data.me.type : ''

            switch (usertype) {
                case "Owner":
                  history.push('/owner-form')
                  break;
                case "Tenant":
                    history.push('/tenant-form')
                  break;
                case "Property Agent":
                  history.push('/property-form')
                  break;
            }
        }
    }, [data])

     


    const [login] = useMutation(LOGIN)

    const onSubmitForm = (e) =>{
        e.preventDefault();
        const submit = {
            email: email,
            password: password
        }
        login({
            variables:{
                email:submit.email,
                password:submit.password
            }
        }).then(res=>{
            const usertoken = res.data.login
            const userType = usertoken.split("||||")[1];
            const token = usertoken.split("||||")[0];
            localStorage.setItem('token',token)
            // window.location.reload()
            switch (userType) {
                case "Owner":
                  history.push('/owner-form')
                  break;
                case "Tenant":
                    history.push('/tenant-form')
                  break;
                case "Property Agent":
                  history.push('/property-form')
                  break;
            }
        })
    }

    return (
        <div className="p-10 bg-green-200 h-screen">
            <div className='w-full max-w-md m-auto bg-yellow-50 rounded-lg border shadow-default py-10 px-16'>
                <h1 className='text-2xl font-medium text-primary mt-4 mb-12 text-center'>
                    Login Account 🔐
                </h1>

                <form onSubmit={onSubmitForm}>
                    <div>
                        <label htmlFor='email'>Email</label>
                        <input
                            className={`w-full p-2 text-primary border rounded-md outline-none text-sm transition duration-150 ease-in-out mb-4
                            focus:ring-2 focus:ring-blue-600 `}
                            type="email"
                            name="email"
                            value={email}
                            onChange={(e)=>setEmail(e.target.value)}
                        />
                    </div>

                    <div>
                        <label htmlFor='password'>Password</label>
                        <input
                            className={`w-full p-2 text-primary border rounded-md outline-none text-sm transition duration-150 ease-in-out mb-4
                            focus:ring-2 focus:ring-blue-600 `}
                            type="password"
                            name="password"
                            value={password}
                            onChange={(e)=>setPassword(e.target.value)}
                        />
                    </div>

                    <div className='flex justify-center items-center mt-6'>
                        <button type="submit" className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-6 border border-blue-500 hover:border-transparent rounded shadow">
                            Login
                        </button>
                    </div>
                </form>


            </div>
        </div>
    )
}
