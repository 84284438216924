import React, { Component } from 'react'

export default class Main extends Component {
     state={
         name: 'john'
     }
     eventhandler =()=>{
         this.setState({name: 'doe'})
     }
    render() {
        return (
            <div>
                <h2>Class base Component</h2>
                {this.state.name}
                <button onClick={this.eventhandler}>change</button>
                {this.state.name}
            </div>
        )
    }
}
