import React from 'react'

export default function Services() {
    return (
        <div className="container mt-5">
            

            <div className="flex flex-row md:px-5 xs:px-2">
                {/* start products put in section */}
                <div className="flex flex-col space-y-5">
                  <h1 className="text-center text-4xl font-bold">OUR SERVICES</h1>
                  <div className="flex justify-center">
                  <div className="wow animate__fadeInDown bg-green-600 h-1 w-60 justify-center"></div>
                  </div>
                   
                     <div className="flex md:flex-row xs:flex-col md:gap-x-14 xs:gap-x-0 xs:gap-y-5 md:px-14 xs:px-5 place-content-center w-full">
                        <div className="rounded-lg shadow-md border-gray-100 border-2 py-2 w-full hover:opacity-75 cursor-pointer space-y-5
                        transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-105 wow animate__fadeInLeft" data-wow-duration="2s" data-wow-delay=".10s">
                            <h1 className="flex justify-center uppercase text-lg font-semibold mt-5">MYKEYPEE SERVICE</h1>
                            <div className="flex justify-center">
                                <img src="./images/Main_Image.png" className="display-block w-full h-72 px-10" />
                            </div>
                            <p className="text-justify p-5">
                                <b>Our signature service in assisting owner to deliver keys to different real estate agents for property viewing, either for rental or sales, 7 days a week and all year round.
                                    </b><br/><br/>
                                In the current market conditions, tenants have more viewing choices than usual, hence owner has to engage multiple real estate agents for property viewing but do not want to duplicate multiple spare keys.<br/><br/>
                                Keypee Service provide the key access to your property by dealing with different real estate agents.<br/><br/>
                                Owner just need to stay at home (saving time and hassle) while the property viewing services taken care by the professional.
                            </p>
                        </div>

                        <div className="rounded-lg shadow-md border-gray-100 border-2 py-2 w-full hover:opacity-75 cursor-pointer space-y-5
                          transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-105 wow animate__fadeInLeft" data-wow-duration="2s" data-wow-delay=".20s">
                            <h1 className="flex justify-center uppercase text-lg font-semibold mt-5">Keysafer Service</h1>
                            <div className="flex justify-center">
                                <img src="./images/Main_Image1.png" className="display-block w-full h-72 px-10" />
                            </div>
                            <p className="text-justify p-5">
                            <b>Keeping spare keys with us in case of key misplace and just for peace of mind.</b><br/><br/>
                            Misplaced keys are a hassle especially the one involved in changing locks and during holiday season. 
                            Keykeeper service are here all year round to provide the spare keys.
                            </p>
                        </div>

                        <div className="rounded-lg shadow-md border-gray-100 border-2 py-2 w-full hover:opacity-75 cursor-pointer space-y-5
                          transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-105 wow animate__fadeInLeft" data-wow-duration="2s" data-wow-delay=".30s">
                            <h1 className="flex justify-center uppercase text-lg font-semibold mt-5">Keysitter Service</h1>
                            <div className="flex justify-center">
                                <img src="./images/Main_Image1.jpg" className="display-block w-full h-72 px-10" />
                            </div>
                            <p className="text-justify p-5">
                                <b>One-of-a-kind house sitter service for your home. *Advance booking required</b><br/><br/>
                                Have a bulk item sending to your property but you are not around, 
                                or having a part-time maid cleaning service while you can go for short walk or run, 
                                or just taking some personal time off. 
                                Keysitter service provide in-person service looking after your property while you are away
                            </p>
                        </div>

                        <div className="rounded-lg shadow-md border-gray-100 border-2 py-2 w-full hover:opacity-75 cursor-pointer space-y-5
                          transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-105 wow animate__fadeInLeft" data-wow-duration="2s" data-wow-delay=".50s">
                            <h1 className="flex justify-center uppercase text-lg font-semibold mt-5">MYKEYPEE EXTRA SERVICE</h1>
                            <div className="flex justify-center">
                                <img src="./images/extra-service.jpg" className="display-block w-full h-72 px-10" />
                            </div>
                            <p className="text-justify p-5">
                               <b>Just like your vehicle, it needs to be start once awhile to keep the engine tires, and electronics running.</b>  
                               *Advance booking required<br/><br/>
                                Keypee Extra Service provide the same cool start service to your property where we check the following:<br/><br/>
                                <ul>
                                    <li>Water taps and toilet flushes</li>
                                    <li>Air conditioning unit</li>
                                    <li>Doors and windows</li>
                                    <li>Latches/locks</li>
                                    <li>Light and fan switches</li>
                                    <li>Dampness or water spots</li>
                                    <li>And others services</li>
                                </ul>
                            </p>
                        </div>
                        
                    </div>

                    <div className="flex flex-col wow animate__fadeInLeft" data-wow-duration="2s" data-wow-delay=".50s">
                        <div className="m-auto space-y-2">
                        <h1 className="text-center text-4xl font-bold">Real Life Story by the Owner</h1>
                        <div className="wow animate__fadeInDown bg-green-600 h-1 w-full justify-center"></div>

                        <div className="flex md:flex-row xs:flex-col md:space-x-2 xs:space-y-2 md:px-14 xs:px-0">

                        <div className="rounded-lg shadow-md border-gray-100 border-2 py-2 w-full hover:opacity-75 cursor-pointer space-y-5
                          transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-105 wow animate__fadeInLeft" data-wow-duration="2s" data-wow-delay=".30s">
                    
                            <div className="flex justify-center">
                                <img src="./images/1-story.jpg" className="display-block w-full h-72 px-10" />
                            </div>
                            <p className="text-justify p-5">
                            Multiple Real Estate Agents engagement, who should I pass the key to? or I need to open the door each time there is a viewing?
                            </p>
                        </div>

                        <div className="rounded-lg shadow-md border-gray-100 border-2 py-2 w-full hover:opacity-75 cursor-pointer space-y-5
                          transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-105 wow animate__fadeInLeft" data-wow-duration="2s" data-wow-delay=".30s">
                    
                            <div className="flex justify-center">
                                <img src="./images/2-story.jpg" className="display-block w-full h-72 px-10" />
                            </div>
                            <p className="text-justify p-5">
                            Real Estate Agents holding the property keys are not around, engage with another viewing, just not available.
                            </p>
                        </div>

                        <div className="rounded-lg shadow-md border-gray-100 border-2 py-2 w-full hover:opacity-75 cursor-pointer space-y-5
                          transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-105 wow animate__fadeInLeft" data-wow-duration="2s" data-wow-delay=".30s">
                    
                            <div className="flex justify-center">
                                <img src="./images/3-story.jpg" className="display-block w-full h-72 px-10" />
                            </div>
                            <p className="text-justify p-5">
                            Owner cannot keep track of who is holding their keys
                            </p>
                        </div>

                        <div className="rounded-lg shadow-md border-gray-100 border-2 py-2 w-full hover:opacity-75 cursor-pointer space-y-5
                          transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-105 wow animate__fadeInLeft" data-wow-duration="2s" data-wow-delay=".30s">
                    
                            <div className="flex justify-center">
                                <img src="./images/4-story.jpg" className="display-block w-full h-72 px-10" />
                            </div>
                            <p className="text-justify p-5">
                            Real Estate Agent drops the key into the mailbox to pass to another Real Estate Agent for viewing
                            </p>
                        </div>

                        </div>

                        <div className="flex md:flex-row xs:flex-col md:space-x-2 xs:space-y-2 md:px-14 xs:px-0">

                        <div className="rounded-lg shadow-md border-gray-100 border-2 py-2 w-full hover:opacity-75 cursor-pointer space-y-5
                          transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-105 wow animate__fadeInLeft" data-wow-duration="2s" data-wow-delay=".30s">
                    
                            <div className="flex justify-center">
                                <img src="./images/5-story.png" className="display-block w-full h-72 px-10" />
                            </div>
                            <p className="text-justify p-5">
                            Keys lost/missing/misplace, especially for single occupant and elderly. No one to get help from other than locksmith.
                            </p>
                        </div>

                        <div className="rounded-lg shadow-md border-gray-100 border-2 py-2 w-full hover:opacity-75 cursor-pointer space-y-5
                          transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-105 wow animate__fadeInLeft" data-wow-duration="2s" data-wow-delay=".30s">
                    
                            <div className="flex justify-center">
                                <img src="./images/6-story.jpg" className="display-block w-full h-72 px-10" />
                            </div>
                            <p className="text-justify p-5">
                            Owner normally have no tenant profiles or feedback from tenants that had viewed their property.
                            </p>
                        </div>

                        <div className="rounded-lg shadow-md border-gray-100 border-2 py-2 w-full hover:opacity-75 cursor-pointer space-y-5
                          transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-105 wow animate__fadeInLeft" data-wow-duration="2s" data-wow-delay=".30s">
                    
                            <div className="flex justify-center">
                                <img src="./images/7-story.jpg" className="display-block w-full h-72 px-10" />
                            </div>
                            <p className="text-justify p-5">
                            Toilet wall, furniture, and kitchen sink molding due to moisture.
                            </p>
                        </div>

                        <div className="rounded-lg shadow-md border-gray-100 border-2 py-2 w-full hover:opacity-75 cursor-pointer space-y-5
                          transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-105 wow animate__fadeInLeft" data-wow-duration="2s" data-wow-delay=".30s">
                    
                            <div className="flex justify-center">
                                <img src="./images/8-story.png" className="display-block w-full h-72 px-10" />
                            </div>
                            <p className="text-justify p-5">
                            Electrical appliances and furniture found not working during tenants viewing.  
                            </p>
                        </div>

                        </div>


                    </div>
                    </div>
                </div>
                {/* end product list */}
                
            </div>
        </div>
    )
}
