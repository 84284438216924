import React from 'react'

export default function BusinesssAdmin() {
    return (
        <div className="container">
            <div className="flex h-screen bg-dark-gray max-w-full">
                <div className="bg-gray-800 p-10">
                    1
                </div>
                <div className="bg-yellow-50">
                    2
                </div>
            </div>
        </div>
    )
}
