import React from 'react'

export default function Petition() {
    return (
        <div>
            <div className="space-y-5">
                <h1 className="text-4xl  text-center font-bold uppercase ">Make A Petition for Service Coverage</h1>
                <div className="bg-green-600 h-1 max-w-full text-center w-1/2 mx-auto"></div>

                <div className="flex flex-col px-10 w-full mt-10">
                <div className="">
                    <form className="space-y-2">
                            <div className="space-y-2">
                                <label className="text-xs font-bold text-gray-600 block">Name</label>
                                <input
                                    name="name"
                                    type="text"
                                    placeholder="Enter Name"
                                    className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring focus:border-blue-300"
                                >
                                </input>
                            </div>

                            <div className="space-y-2">
                                <label className="text-xs font-bold text-gray-600 block">Mobile</label>
                                <input
                                    name="name"
                                    type="text"
                                    placeholder="Enter Phone"
                                    className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring focus:border-blue-300"
                                >
                                </input>
                            </div>

                            <div className="space-y-2">
                                <label className="text-xs font-bold text-gray-600 block">Address 1</label>
                                <input
                                    name="name"
                                    type="text"
                                    placeholder="Address 1"
                                    className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring focus:border-blue-300"
                                >
                                </input>
                            </div>

                            <div className="space-y-2">
                                <label className="text-xs font-bold text-gray-600 block">Address 2</label>
                                <input
                                    name="name"
                                    type="text"
                                    placeholder="Address 2"
                                    className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring focus:border-blue-300"
                                    required
                                >
                                </input>
                            </div>

                            <div className="flex md:flex-row xs:flex-col justify-between xs:space-x-1 lg:space-x-0">
                                <div className="space-y-2">
                                    <label className="text-xs font-bold text-gray-600 block">City</label>
                                    <input
                                        size={40}
                                        name="name"
                                        type="text"
                                        placeholder="Enter city"
                                        className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring focus:border-blue-300"
                                    >
                                    </input>
                                </div>

                            {/* <div className=" justify-between space-x-2"> */}
                            <div className="space-y-2 w-1/3">
                                <label className="text-xs font-bold text-gray-600 block">State</label>
                                <select className="rounded-md border border-gray-300 focus:outline-none w-full h-10"
                                >
                                    <option>Please Select </option>
                                    <option>Selangor</option>
                                    <option>Kuala Lumpur</option>
                                    <option>Melaka</option>
                                    <option>Johor Baru</option>
                                    <option>Pahang</option>
                                </select>
                            </div>

                            <div className="space-y-2">
                                    <label className="text-xs font-bold text-gray-600 block">Postal Code</label>
                                    <input
                                        
                                        name="name"
                                        type="text"
                                        placeholder="Enter postal code"
                                        className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring focus:border-blue-300"
                                    >
                                    </input>
                                </div>
                            </div>
                            
                                {/* <div className="mr-60">
                                    <label className="text-xs font-bold text-gray-600 block">County</label>
                                    <select className="rounded-md border border-gray-300 focus:outline-none w-full"
                                     onChange={(e)=>setCountry(e.target.value)} value={country}>
                                    <option>Malaysia</option>
                                    <option>Indonesia</option>
                                    <option>Singapore</option>
                                </select>
                                </div> */}
                      {/* </div> */}

                    <div className="flex justify-center">
                      <button className=" bg-green-500 text-white text-xl uppercase rounded-lg border-green-500 
                       md:px-72 xs:px-5 md:py-5 xs:py-1 focus:outline-none" 
                        >Submit</button>
                    </div>
                        
                    </form>
                </div>
            </div>

            </div>
        </div>
    )
}
