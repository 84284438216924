import {gql} from "@apollo/client";

export const LOGIN = gql`
mutation login (
 $email: String!
 $password: String!
) {
    login(
        email: $email
        password: $password
    )
}
`

export const SIGNUP = gql`
    mutation register (
        $name: String!
        $email: String!
        $password: String!
        $type: userType!
    ) {
        register(
            name: $name
            email: $email
            password: $password
            type: $type
        )
    }
`
// export const OWNERFORM = gql`
// mutation createOwner (
//     $name: String!
//     $mobile_number: $String!
//     $email: String!
//     $property_details: String!
//     $property_photo: String
//     $property_description: String
//     $key_inquest: String
// ){
//     createOwner(
//         name:$name
//         mobile_number:$mobile_number
//         email:$email
//         property_details:$property_details
//         property_photo
//     )
// }
// `

