import React, {useState} from 'react'
import {useMutation} from '@apollo/client'
import {LOGIN} from '../GraphQL/Mutation'

export default function PropertyAgent() {

    const [email,setEmail]=useState("")
    const [password,setPassword] = useState("")

    const submitHandler=(e)=>{
        e.preventDefault()
    }

    const [login] = useMutation(LOGIN)
    const onSubmitForm = (e) =>{
        e.preventDefault();
        const submit = {
            email: email,
            password: password
        }
        login({
            variables:{
                email:submit.email,
                password:submit.password
            }
        }).then(res=>{
            console.log(res.data)
        })
    }

    return (
        <div className="p-10 bg-green-200 h-auto">
            <div className='w-full max-w-lg m-auto bg-yellow-50 rounded-lg border shadow-default py-10 px-16'>
                <h1 className='text-2xl font-medium text-primary mt-4 mb-12 text-center'>
                    Please Fill Up This PropertyAgent Form
                </h1>

                <form onSubmit={submitHandler}>
                    <div>
                        <label htmlFor='email'>Name</label>
                        <input
                            className={`w-full p-2 text-primary border rounded-md outline-none text-sm transition duration-150 ease-in-out mb-4
                            focus:ring-2 focus:ring-blue-600 `}
                            type="email"
                            name="email"
                            value={email}
                            onChange={(e)=>setEmail(e.target.value)}
                        />
                    </div>

                    <div>
                        <label htmlFor='password'>Mobile</label>
                        <input
                            className={`w-full p-2 text-primary border rounded-md outline-none text-sm transition duration-150 ease-in-out mb-4
                            focus:ring-2 focus:ring-blue-600 `}
                            type="text"
                            name="password"
                            value={password}
                            onChange={(e)=>setPassword(e.target.value)}
                        />
                    </div>

                    <div>
                        <label htmlFor='password'>Email</label>
                        <input
                            className={`w-full p-2 text-primary border rounded-md outline-none text-sm transition duration-150 ease-in-out mb-4
                            focus:ring-2 focus:ring-blue-600 `}
                            type="text"
                            name="password"
                            value={password}
                            onChange={(e)=>setPassword(e.target.value)}
                        />
                    </div>
                    <div>
                        <label htmlFor='password'>Business Card</label>
                        <input
                            className={`w-full p-2 text-primary border rounded-md outline-none text-sm transition duration-150 ease-in-out mb-4
                            focus:ring-2 focus:ring-blue-600 `}
                            type="file"
                        />
                    </div>

                    <div className='flex justify-center items-center mt-6'>
                        <button onClick={onSubmitForm} type="submit" className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-6 border border-blue-500 hover:border-transparent rounded shadow">
                            Submit
                        </button>
                    </div>
                </form>


            </div>
        </div>
    )
}
