import React from 'react';
import WOW from 'wowjs'
import {AiFillClockCircle,AiFillBank,AiFillPhone,AiFillHome,AiTwotonePropertySafety,AiOutlineForm,AiOutlineContacts} from 'react-icons/ai'
import {GiLetterBomb} from 'react-icons/gi';
import {VscFeedback} from 'react-icons/vsc';
import {FaStoreAlt,FaMoneyBillAlt} from 'react-icons/fa';
export default function HowWeWorks() {
    return (
        <div>
            <div className="px-2 space-y-5 bg-yellow-50 py-5">
                <h1 className="text-center text-4xl font-bold">HOW WE WORKS</h1>
                <div className="flex justify-center">
                    <div className="wow animate__fadeInDown bg-green-600 h-1 w-72 justify-center"></div>
                </div>
                <div className="flex tracking-tighter flex-col ">
                    {/* style={{backgroundImage: "url(" + "./images/Main_Image.png" + ")"}} */}
                    <div className="space-y-2  wow animate__lightSpeedInLeft" data-wow-duration="2s" data-wow-delay=".10s">
                        <h1 className=" text-xl font-bold ml-14">MyKeypee Service for Owner</h1>
                        <div className="flex md:flex-row xs:flex-col px-14 space-x-2">
                            <div className="flex-col md:w-1/5 xs:w-full xs:w-full space-y-5">
                                <AiFillClockCircle size="50" className="text-yellow-300"></AiFillClockCircle>
                                <p>Owner to request for the service by filling our Keypee Service Request for Owner.</p>
                            </div>
                            <div className="flex-col md:w-1/5 xs:w-full space-y-5">
                                <AiFillPhone size="50" className="text-yellow-300"></AiFillPhone>
                                <p>Our friendly MyKeypee team will contact the owner and accept the keys from owner</p>
                            </div>
                            <div className="flex-col md:w-1/5 xs:w-full space-y-5">
                                <AiFillHome size="50" className="text-yellow-300"></AiFillHome>
                                <p>For any viewing, Owner just need to inform MyKeypee team to work with the related Real Property Agents. (6 hours in advance, so the keys delivery can be arranged)</p>
                            </div>
                            <div className="flex-col md:w-1/5 xs:w-full space-y-5">
                                <AiTwotonePropertySafety size="50" className="text-yellow-300"></AiTwotonePropertySafety>
                                <p>Real Property Agents bring tenants to the property, and MyKeypee team will release the keys for viewing, and secure back the keys after viewing.</p>
                            </div>
                            <div className="flex-col md:w-1/5 xs:w-full space-y-5">
                                <GiLetterBomb size="50" className="text-yellow-300"></GiLetterBomb>
                                <p>When the tenant accepts the Letter of Offer, the keys will be release back.</p>
                            </div>
                        </div>

                        <div className="bg-green-600 h-px text-center w-full mx-auto"></div>
                    </div>

                    <div className="space-y-2 wow animate__lightSpeedInRight" data-wow-duration="2s" data-wow-delay=".10s">
                        <h1 className="text-xl font-bold ml-14">MyKeypee Service for Tenant</h1>
                        <div className="flex px-14 space-x-2">
                            <div className="flex-col w-1/2 space-y-5">
                                <AiOutlineForm size="50" className="text-yellow-300"></AiOutlineForm>
                                <p>Tenant are obliged to fill in the Tenant Visit Form during the property viewings so Owner is aware of the tenant’s profile (name, job title and work location) and visiting date and time. </p>
                            </div>
                            <div className="flex-col w-1/2 space-y-5">
                                <VscFeedback size="50" className="text-yellow-300"></VscFeedback>
                                <p>Tenant are voluntarily asked to give feedback on the viewings, good or bad. To help owner improve on their property</p>
                            </div>
                        </div>
                        <div className="bg-green-600 h-px text-center w-full mx-auto"></div>
                    </div>

                    <div className="space-y-2 wow animate__backInUp" data-wow-duration="2s" data-wow-delay=".10s">
                        <h1 className="text-xl font-bold ml-14">Keypee Service for Real Estate Agent</h1>
                        <div className="flex px-14 space-x-2">
                            <div className="flex-col space-y-5">
                                <FaStoreAlt size="50" className="text-yellow-300"></FaStoreAlt>
                                <p>Real Estate Agent are required to registered themselves in MyKeypee.com using Real Estate Agent Form by
                                    providing business credential so MyKeypee team can verify the profile before releasing the keys for viewing</p>
                            </div>
                        </div>
                        <div className="bg-green-600 h-px text-center w-full mx-auto"></div>
                    </div>

                    <div className="space-y-2 wow animate__fadeInTopRight" data-wow-duration="2s" data-wow-delay=".10s">
                        <h1 className="text-xl font-bold ml-14">MyKeysafer Service</h1>
                        <div className="flex px-14 space-x-2">
                            <div className="flex-col w-1/2 space-y-5">
                                <FaMoneyBillAlt size="50" className="text-yellow-300"></FaMoneyBillAlt>
                                <p>Customer to request for the service by filling our Keysafer Service Request</p>
                            </div>
                            <div className="flex-col w-1/2 space-y-5">
                                <AiOutlineContacts size="50" className="text-yellow-300"></AiOutlineContacts>
                                <p>Our friendly MyKeypee team will contact the customer to arrange for the service. For urgent key request, rider service will be use and the delivery charge are bear by customer.
                                    For none urgent key request, delivery will be done within 4 hours. </p>
                            </div>
                        </div>
                        <div className="bg-green-600 h-px text-center w-full mx-auto"></div>
                    </div>

                    <div className="space-y-2 wow animate__zoomIn" data-wow-duration="2s" data-wow-delay=".10s">
                        <h1 className="text-xl font-bold ml-14">Keysitter Service</h1>
                        <div className="flex px-14 space-x-2">
                            <div className="flex-col w-1/2 space-y-5">
                                <FaMoneyBillAlt size="50" className="text-yellow-300"></FaMoneyBillAlt>
                                <p>Customer to request for the service by filling our Keysitter Service Request
                                    (at least 2 days advance booking is required depending on the service request type</p>
                            </div>
                            <div className="flex-col w-1/2 space-y-5">
                                <AiOutlineContacts size="50" className="text-yellow-300"></AiOutlineContacts>
                                <p>Our friendly MyKeypee team will contact the customer to arrange for the service</p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}
