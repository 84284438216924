import React, {useState,useEffect} from 'react'
import {useMutation,useQuery} from '@apollo/client'
import {LOGIN} from '../GraphQL/Mutation'
import {LOAD_ME} from '../GraphQL/Query';
import { useHistory } from 'react-router';

export default function Owner() {

    const [email,setEmail]=useState("")
    const [password,setPassword] = useState("")

    const submitHandler=(e)=>{
        e.preventDefault()
    }

    const history = useHistory()

    const { loading, data } = useQuery(LOAD_ME)

    useEffect(() => {
        if (data) {
            const usertype = data.me ? data.me.type : '' 
            if(usertype === '') {

                window.location.reload();

                history.push('/login')
            } 
        }
    }, [data])

    const [login] = useMutation(LOGIN)
    const onSubmitForm = (e) =>{
        e.preventDefault();
        const submit = {
            email: email,
            password: password
        }
        login({
            variables:{
                email:submit.email,
                password:submit.password
            }
        }).then(res=>{
            console.log(res.data)
        })
    }

    return (
        <div className="p-10 bg-green-200 h-auto">
            <div className='w-full max-w-lg m-auto bg-yellow-50 rounded-lg border shadow-default py-10 px-16'>
                <h1 className='text-2xl font-medium text-primary mt-4 mb-12 text-center'>
                    Please Fill Up This Owner Form
                </h1>

                <form onSubmit={submitHandler}>
                    <div>
                        <label htmlFor='email'>Name</label>
                        <input
                            className={`w-full p-2 text-primary border rounded-md outline-none text-sm transition duration-150 ease-in-out mb-4
                            focus:ring-2 focus:ring-blue-600 `}
                            type="email"
                            name="email"
                            value={email}
                            onChange={(e)=>setEmail(e.target.value)}
                        />
                    </div>

                    <div>
                        <label htmlFor='password'>Mobile</label>
                        <input
                            className={`w-full p-2 text-primary border rounded-md outline-none text-sm transition duration-150 ease-in-out mb-4
                            focus:ring-2 focus:ring-blue-600 `}
                            type="text"
                            name="password"
                            value={password}
                            onChange={(e)=>setPassword(e.target.value)}
                        />
                    </div>

                    <div>
                        <label htmlFor='password'>Email</label>
                        <input
                            className={`w-full p-2 text-primary border rounded-md outline-none text-sm transition duration-150 ease-in-out mb-4
                            focus:ring-2 focus:ring-blue-600 `}
                            type="text"
                            name="password"
                            value={password}
                            onChange={(e)=>setPassword(e.target.value)}
                        />
                    </div>
                    <div>
                        <label htmlFor='password'>Unit/Property Details</label>
                        <input
                            className={`w-full p-2 text-primary border rounded-md outline-none text-sm transition duration-150 ease-in-out mb-4
                            focus:ring-2 focus:ring-blue-600 `}
                            type="text"
                            name="password"
                            value={password}
                            onChange={(e)=>setPassword(e.target.value)}
                        />
                    </div>
                    <div>
                        <label htmlFor='password'>Property Photo</label>
                        <input
                            className={`w-full p-2 text-primary border rounded-md outline-none text-sm transition duration-150 ease-in-out mb-4
                            focus:ring-2 focus:ring-blue-600 `}
                            type="file"
                        />
                    </div>

                    <div>
                        <label htmlFor='password'>Key Inquest Request</label>
                        <input
                            className={`w-full p-2 text-primary border rounded-md outline-none text-sm transition duration-150 ease-in-out mb-4
                            focus:ring-2 focus:ring-blue-600 `}
                            type="file"
                        />
                    </div>

                    <div className='flex justify-center items-center mt-6'>
                        <button onClick={onSubmitForm} type="submit" className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-6 border border-blue-500 hover:border-transparent rounded shadow">
                            Submit
                        </button>
                    </div>
                </form>


            </div>
        </div>
    )
}
