import React,{useState,useEffect} from 'react';
import './App.css';
import axios from 'axios';
import {Link} from 'react-router-dom';
import Particles from 'react-particles-js';
import WOW from 'wowjs'
import {AiFillClockCircle,AiFillBank,AiFillPhone,AiFillHome} from 'react-icons/ai'
import Services from './components/services'
import Location from './components/location';
import HowWeWorks from './components/how-we-works';
import Pricing from './components/pricing';
import Petition from './components/petition';
import AboutUs from './components/about-us';
import Footer from './components/footer';

function App() {

  useEffect(()=>{
    new WOW.WOW().init();
  },[])
  return (
    <>

      <div className="container mx-auto flex items-center text-white p-5 space-x-10 justify-between sticky top-0 z-50 bg-transfarent w-full overflow-hidden">

      <div className="flex items-center ">
          <img src="./images/Logo.png" className="md:h-20 md:w-32 xs:w-18 xs:h-14" />
      </div>
      <div className="flex space-x-5">
          <a href="https://docs.google.com/forms/d/e/1FAIpQLSfrz4JTrZejmF0bzeHn4wdHnFuiBhOGteWRyX0VHCjXkd9UAg/viewform?usp=sf_link" target="_blank" 
          className="md:p-4 xs:p-2  bg-blue-600 rounded-md text-white focus:outline-none">Agent SignIn</a>

          <a href="https://docs.google.com/forms/d/e/1FAIpQLSf7a995KkEvIgHozLTbaf4tgrpbioOEqvfMtrj81hprx3eT_w/viewform?usp=sf_link" target="_blank"
          className="md:p-4 xs:p-2 bg-blue-600 rounded-md text-white focus:outline-none">Owner SignIn</a>
      </div>
      </div>
  
    <div className="flex flex-col bg-yellow-50 space-y-10 -mt-32">

    <div className="flex flex-col space-y-10 ">
      <div className="w-full bg-green-600  overflow-hidden h-full"
      style={{backgroundImage: "url(" + "./images/key_banner.jpg" + ")",
      backgroundRepeat:'no-repeat',backgroundSize: 'cover',height:"600px"}}>
        <Particles
            params={{
              "particles": {
                  "number": {
                      "value": 100
                  },
                  "size": {
                      "value": 3
                  },
                  "shape":{
                    "type": "star"
                  },
                  "color":{
                    "value": "#f70707"
                  },
              },
              "interactivity": {
                  "events": {
                      "onhover": {
                          "enable": true,
                          "mode": "repulse"
                      },
                      "onClick":{
                        "enable":true,
                        "mode":'push'
                      }
                  },
              },
          }}
          height="100vh"
  />
      </div>

      {/* <Services/>
      <Location/> */}

      <div className="wow animate__fadeInDown absolute md:top-56 xs:top-32 text-center  md:left-40 md:right-40 
      xs:left-0 xs:right-0 md:text-4xl xs:text-xl md:text-white xs:text-green-300 font-bold" data-wow-duration="2s" data-wow-delay=".5s">
         <h1>Good day, our esteem customers</h1><br/>
          <p className="">We provide environmentally friendly and cost-effective ways in property renting, keys management, 
          and most importantly, time saving for EVERYBODY with our one-of-a-kind services. </p>
        </div>
    </div>

    <Services/>
    <hr/>
    <Location/>
    <hr/>
    <HowWeWorks/>
    <hr/>
    <Pricing/>
    <hr/>
    <Petition/>
    <hr/>
    <AboutUs/>
    <Footer></Footer>
    </div>
  </>
  );
}

export default App;

//https://previews.123rf.com/images/tiero/tiero1105/tiero110500086/9591766-smiling-architect-and-3d-project-house.jpg
// "interactivity": {
//   "events": {
//       "onhover": {
//           "enable": true,
//           "mode": "repulse"
//       }
//   }
// }

// params={{
//   "particles": {
//       "number": {
//           "value": 150
//       },
//       "size": {
//           "value": 3
//       },
//       shape:{
//         type:'triangle'
//       },
//     "interactivity": {
//       "events": {
//         "onhover": {
//           "enable": true,
//           "mode": "repulse"
//         }
//       }
//     }
//   },
// }}

{/* <Particles
className="w-full mx-auto bg-green-600 absolute top-0 bottom-0"
    params={{
      "particles": {
          "number": {
              "value": 200
          },
          "size": {
              "value": 3
          },
          "shape":{
            "type": "star"
          },
          "color":{
            "value": "#f70707"
          },
      },
      "interactivity": {
          "events": {
              "onhover": {
                  "enable": true,
                  "mode": "repulse"
              },
              "onClick":{
                "enable":true,
                "mode":'push'
              }
          },
      },
  }}
  /> */}