import React from 'react'
import Owner from './owner-form';
import TenantForm from './tenant-form';
import PropertyAgent from './property-form';

export default function Userform() {
    return (
        <div className="flex md:flex-row xs:flex-col">
            <Owner></Owner>
            <TenantForm></TenantForm>
            <PropertyAgent></PropertyAgent>
        </div>
    )
}
